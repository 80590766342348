// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';
import RetailersPage from './pages/RetailersPage';
import ConsumersPage from './pages/ConsumersPage';
import CreateUserForm from './pages/CreateUserForm';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/retailers" element={<ProtectedRoute><RetailersPage /></ProtectedRoute>} />
          <Route path="/consumers" element={<ProtectedRoute><ConsumersPage /></ProtectedRoute>} />
          <Route path="/create-user" element={<ProtectedRoute><CreateUserForm /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
