import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './DashboardPage.css';
import { useAuth } from '../contexts/AuthContext';

const RetailersPage = () => {
  const [retailers, setRetailers] = useState([]);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    fetchRetailers();
  }, []);

  const fetchRetailers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/retailers`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        } // for CORS and sending cookies
      });
      if (response.ok) {
        const data = await response.json();
        setRetailers(data);
      } else {
        // Handle errors or redirects if not authenticated
        console.error('Failed to fetch retailers');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching retailers:', error);
    }
  };

  const handleDeleteUser = async (retailerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/delete-user/${retailerId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        // Refresh retailers list after successful deletion
        fetchRetailers();
      } else {
        console.error('Failed to delete retailer');
        // Handle error
      }
    } catch (error) {
      console.error('Error deleting retailer:', error);
      // Handle error
    }
  };

  const handleConfirmDelete = (retailerId) => {
    // Show confirmation dialog here
    if (window.confirm('Are you sure you want to delete this retailer?', retailerId)) {
        handleDeleteUser(retailerId);
    }
  };

  return (
    <div className="retailers-page">
      <div className="header">
        <h1>Retailers</h1>
        <div className="action-buttons">
            <button onClick={() => navigate('/create-user')} className="button">Create Retailer</button>
          <button onClick={() => navigate('/consumers')} className="button">Go to Consumers</button>
        </div>
      </div>
      <div className="content">
        <h2>Retailers List</h2>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Username</th>
              <th>Mobile Number</th>
              <th>Joined At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {retailers.map(retailer => (
              <tr key={retailer._id}>
                <td>{retailer._id}</td>
                <td>{retailer.username}</td>
                <td>{retailer.phoneNumber}</td>
                <td>{new Date(retailer.createdAt).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => handleConfirmDelete(retailer._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={ async () => {
          // Implement logout functionality
          await logout();
          navigate('/');
        }} className="account-option logout">Logout</button>
    </div>
  );
};

export default RetailersPage;
