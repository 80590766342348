import React, { useState } from 'react';
import './CreateUserForm.css';
import { useNavigate } from 'react-router-dom';

const CreateUserForm = () => {
    const navigate = useNavigate();
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [address, setAddress] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        phoneNumber: '',
        accountType: '',
        location: {
            "type": "Point",
            coordinates: [0, 0], // Default coordinates
            address: '',
        },
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleLatitudeChange = (e) => {
        const { value } = e.target;
        setLatitude(value);
        setFormData(prevState => ({
            ...prevState,
            location: {
                ...prevState.location,
                coordinates: [longitude, value]
            }
        }));
    };

    const handleLongitudeChange = (e) => {
        const { value } = e.target;
        setLongitude(value);
        setFormData(prevState => ({
            ...prevState,
            location: {
                ...prevState.location,
                coordinates: [value, latitude]
            }
        }));
    };

    const handleAddressChange = (e) => {
        const { value } = e.target;
        setAddress(value);
        setFormData(prevState => ({
            ...prevState,
            location: {
                ...prevState.location,
                address: value
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/registerusers`, {
                method: 'POST',
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
              });

              if (response.ok) {
                console.log(response.data); // Assuming the API returns some data
                if (formData.accountType === 'accountType1') {
                    navigate('/retailers')
                } else {
                    navigate('/consumers')
                }
              } else {
                const errorText = await response.text();
                const errorMessage = `Failed to create user bill: ${errorText}`;
                alert(errorMessage);
              }
        } catch (error) {
            console.error('Error creating user:', error);
            // Handle error
        }
    };

    return (
        <div className="container">
            <h2>Create User</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input type="text" className="form-control" id="username" name="username" value={formData.username} onChange={handleFormChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleFormChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleFormChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="accountType">Account Type</label>
                    <select className="form-control" id="accountType" name="accountType" value={formData.accountType} onChange={handleFormChange}>
                        <option value="">Select Account Type</option>
                        <option value="accountType1">Account Type 1</option>
                        <option value="accountType2">Account Type 2</option>
                    </select>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="latitude">Latitude</label>
                        <input type="text" className="form-control" id="latitude" name="latitude" value={latitude} onChange={handleLatitudeChange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="longitude">Longitude</label>
                        <input type="text" className="form-control" id="longitude" name="longitude" value={longitude} onChange={handleLongitudeChange} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" className="form-control" id="address" name="address" value={address} onChange={handleAddressChange} />
                    <small className="form-text text-muted">
                        {formData.accountType === 'accountType1' ? 'This field is required for retailers.' : ''}
                    </small>
                </div>
                <button type="submit" className="btn btn-primary">Create User</button>
            </form>
        </div>
    );
};

export default CreateUserForm;
